<template>
  <ion-page >
    <div id="dashboard">
    <base-layout>
      <!--header-bar></header-bar-->
      <ion-content :scroll-x="false" :scroll-y="false">
        <div class="header-text">
          <strong class="title" style="margin: 5px">Celebrating 20 Years of Europe's Cancer Immunotherapy Meeting

            <br> </strong>
        </div>


        <div class="dashboard-content" style="overflow:scroll;padding-bottom:70px">
          <div class="loading-indicator" v-if="menulinks.length == 0">
            <ion-spinner></ion-spinner>
            <div style="color: #F49507 !important">Initializing Data</div>
          </div>
          <div class="menu-wrapper">
            <template v-for="link in menulinks" :key="link.id"> 
              <a
                  @click="openLink(link)"
                  class="tile-item" v-if="!isExternalLink(link) && link.type!='item item-divider'" 
                  style="position:relative; background: #E44D7D !important">
                <i class="dashboard-item-icon" :class="link.icon_style"></i>

                <span class="text" v-html="link.title['en']" style="color: #fff"></span>
                <span v-if="link.target == '#/app/posts' && unreadNews > 0"
                      class="position-absolute translate-middle badge rounded-pill bg-primary"
                      style="top:15px; right:0px">
                {{ unreadNews }}
              </span>
              </a>
              <a
                  :href="link.target" target="_blank"
                  class="tile-item" v-if="isExternalLink(link)"
                  style="position:relative; background: white !important">
                <i class="dashboard-item-icon" :class="link.icon_style"></i>

                <span class="text" v-html="link.title['en']" style="color: #009B4A"></span>
                <span v-if="link.target == '#/app/posts' && unreadNews > 0"
                      class="position-absolute translate-middle badge rounded-pill bg-primary"
                      style="top:15px; right:0px">
                {{ unreadNews }}
              </span>
              </a>
            </template>

          </div>
        </div>

      </ion-content>
    </base-layout>
    </div>
  </ion-page>
</template>
<script>
import {
  IonPage,
  IonSpinner,
  IonContent,
  toastController
} from "@ionic/vue";
import {mapState, mapActions, mapGetters} from "vuex";
import {isPlatform} from '@ionic/vue';
import router from "@/router";

const config = require('@/config')

export default {
  components: {
    IonPage,
    IonSpinner,
    IonContent,
  },
  data() {
    return {
      isDesktop: null,
      menulinks: [],
      title: config.full_name,
      confDate: config.conf_date,
      currentTime: '',
      polls: [],
      questions: [],
    }
  },
  computed: {
    ...mapState('sync', ['updateFinished', 'syncActive', 'downloadSize', 'downloadLabel', 'currentProgress']),
    ...mapState('personalDB', ['unreadNews']),
    ...mapGetters('auth', ['user'])
  },
  methods: {
    ...mapActions('menulinks', ['getAllLinks', 'getParents']),
    ...mapActions('sync', ['start']),
    ...mapActions('auth', ['GET_USERDATA']),


    async showSyncToast() {
      const toast = await toastController
          .create({
            message: 'Application is now up to date',
            duration: 2000,
            position: 'bottom',
            animated: 'true',
            color: 'primary',

          })
      return toast.present();
    },


    checkRedirect(link) {
      if (!link.target || link.target.indexOf('undefined') != -1 || link.target == 'null') {
        link.redirect = '/app/dashboard/' + link.id;
      } else {
        if (new RegExp("^(http|https)://").test(link.target)) {
          link.redirect = link.target
        } else {
          link.redirect = link.target.split('#')[1]
        }

      }
    },

    isExternalLink(link){
      return new RegExp("^(http|https)://").test(link.target)
    },

    openLink(link) {
      console.log(link)
      let _link = this.determineTarget(link)


      if (new RegExp("^(http|https)://").test(_link)) {
        window.open(_link, '_blank', 'location=no');
      } else {
        console.log('lll',_link)
        router.push(_link);
      }
    },

    async update() {
      let res = await this.start();
      if (res) {
        this.showSyncToast();
      }
    },
    determineTarget(link) {
      return link.redirect;
    },
    async prepareLinks() {
      this.menulinks = (await this.getParents()).sort((a, b) => a.order - b.order);
      this.menulinks.map(link => {
        try {
          link._title = JSON.parse(link.title);
        } catch (err) {
          link._title = link.title;
        }
        this.checkRedirect(link);
      });
    }
  },
  async created() {
    this.isDesktop = isPlatform('desktop');
    await this.prepareLinks();


  },
  watch: {
    updateFinished: async function (newVal) {
      if (newVal) {
        this.prepareLinks();
      }
    },
  },
};
</script>
<style lang="scss">


</style>